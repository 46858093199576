import {
    GET_ALL_INVOICES,
    GET_SINGLE_INVOICE,
    RESPONSE_STATUS,
    GET_VIEW_DOC,
    CLEAR_RESPONSE,
} from './sellerInvoiceTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_VIEW_DOC:
            return {
                ...state,
                view_doc: action.payload,
            }
        case GET_ALL_INVOICES:
            return {
                ...state,
                all_invoices: action.payload,
            }
        case GET_SINGLE_INVOICE:
            return {
                ...state,
                single_invoice: action.payload,
            }

        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
