import React, { useReducer } from 'react'
import InvoiceContext from './sellerInvoiceContext'
import InvoiceReducer from './sellerInvoiceReducer'
import { apiCallSeller } from '../../../common/api'
import { response } from '../../common'

import {
    GET_ALL_INVOICES,
    GET_SINGLE_INVOICE,
    RESPONSE_STATUS,
    GET_VIEW_DOC,
    CLEAR_RESPONSE,
} from './sellerInvoiceTypes'

const SellerInvoiceState = (props) => {
    const initialState = {
        all_invoices: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        single_invoice: {
            invoicerecords: [],
            transactionrecords: [],
            appointmentrecord: {},
            locationrecord: {},
            cartvalues: {},
        },
        view_doc: {
            records: [],
            from: '',
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(InvoiceReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllInvoices = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'invoices', formData, '', 'invoice'),
            ])
            const from = fromVariable ? fromVariable : 'Invoices'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_INVOICES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const viewPandaDoc = async (formData, fromVariable = 'viewPandaDoc') => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'viewPandaDoc', formData, '', 'third_party/pandadoc'),
            ])

            if (res.data.status === 'success') {
                console.log(res.data.data.responseData, 'reeee')
                dispatch({
                    type: GET_VIEW_DOC,
                    payload: {
                        records: res.data.data.responseData.length
                            ? res.data.data.responseData
                            : [],
                        from: fromVariable,
                    },
                })

                // dispatch({
                //     type: GET_FILES,
                //     payload: {
                //         records: res.data.data.responseData.files
                //             ? res.data.data.responseData.files
                //             : [],
                //         from: fromVariable,
                //     },
                // })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, fromVariable)
            } else {
                resp.commonErrorResponse(fromVariable)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const invoiceAction = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'invoiceAction'

            const [res] = await Promise.all([
                apiCallSeller('post', 'invoiceAction', formData, '', 'invoice'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleInvoice = async (formData) => {
        try {
            console.log('dfdsjfjs')
            const [res] = await Promise.all([
                apiCallSeller('post', 'invoice', formData, '', 'user/invoice'),
            ])
            const from = 'InvoiceManagement'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_INVOICE,
                    payload: {
                        invoicerecords: res.data.data.responseData.invoiceItems.length
                            ? res.data.data.responseData.invoiceItems
                            : [],
                        transactionrecords: res.data.data.responseData.allTransactions.length
                            ? res.data.data.responseData.allTransactions
                            : [],
                        appointmentrecord: res.data.data.responseData.appointmentDetail
                            ? res.data.data.responseData.appointmentDetail
                            : [],
                        locationrecord: res.data.data.responseData.locationDetail
                            ? res.data.data.responseData.locationDetail
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        invoiceuserrecord: res.data.data.responseData.invoiceUser
                            ? res.data.data.responseData.invoiceUser
                            : [],
                        invoiceaddressrecord: res.data.data.responseData.invoiceAddress
                            ? res.data.data.responseData.invoiceAddress
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const changeProductStatus = async (formData) => {
        try {
            const from = 'productchange'
            const [res] = await Promise.all([
                apiCallSeller('post', 'changeStatus', formData, '', 'invoice'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updateInvoice = async (formData) => {
        try {
            const from = 'invoiceupdate'
            const [res] = await Promise.all([
                apiCallSeller('post', 'update_invoice', formData, '', 'user/invoice'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <InvoiceContext.Provider
            value={{
                // buyer_allproducts: state.buyer_allproducts,
                all_invoices: state.all_invoices,
                responseStatus: state.responseStatus,
                single_invoice: state.single_invoice,
                view_doc: state.view_doc,
                invoiceAction,
                clearResponse,
                getAllInvoices,
                changeProductStatus,
                viewPandaDoc,
                getSingleInvoice,
                updateInvoice,
            }}
        >
            {props.children}
        </InvoiceContext.Provider>
    )
}

export default SellerInvoiceState
